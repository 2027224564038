<template>
  <Dialog
    v-model:visible="productDialog"
    :maximizable="false"
    position="top"
    class="p-fluid p-m-0 p-dialog-maximized"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-user"></i> {{ dialogTitle }}
      </h5>
    </template>
    <div class="p-grid">
      <div class="p-col-12">
        <TabView>
          <TabPanel header="Basic">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="p-field">
                  <label for="associate">Associate ID</label>
                  <InputText
                    id="associate"
                    :disabled="hideDisableFields"
                    v-model="associate.associateID"
                    @blur="verifyId"
                    :class="{ 'bg-light': hideDisableFields }"
                  />
                </div>
                <div class="p-field">
                  <label for="firstName">First Name</label>
                  <InputText
                    id="firstName"
                    v-model.trim="associate.firstName"
                    :class="{ 'p-invalid': submitted && !associate.firstName }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !associate.firstName"
                    >First Name is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="lastName">Last Name</label>
                  <InputText
                    id="lastName"
                    v-model.trim="associate.lastName"
                    :class="{ 'p-invalid': submitted && !associate.lastName }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !associate.lastName"
                    >Last Name is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    v-model="associate.email"
                    :class="{ 'p-invalid': submitted && !associate.email }"
                  />
                  <small class="p-invalid" v-if="submitted && !associate.email"
                    >Email is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="hourlyRate">Hourly Rate</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="hourlyRate"
                    v-model="associate.hourlyRate"
                  />
                </div>
                <div class="p-field">
                  <label for="magicNo">Monthly Individual Magic No</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="magicNo"
                    v-model="associate.mimn"
                  />
                </div>
                <div class="p-field">
                  <label for="indMagicNo"
                    >Monthly Individual Comparable Magic No</label
                  >
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="indMagicNo"
                    v-model="associate.micmn"
                    disabled="true"
                    class="bg-secondary"
                  />
                </div>
              </div>
              <!--.p-col-6-->

              <div class="p-col-6">
                <div class="p-field">
                  <label for="mas">Monthly Average Sale</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    disabled="true"
                    id="mas"
                    v-model="associate.mas"
                    class="bg-secondary"
                  />
                </div>
                <div class="p-field">
                  <label for="maa">Monthly Average Alteration</label>
                  <InputNumber :useGrouping="false" mode="decimal" :minFractionDigits="1" id="maa" v-model="associate.maa" />
                </div>
                <div class="p-field">
                  <label for="12maa">12 Month Average Alteration</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    disabled="true"
                    id="12maa"
                    v-model="associate.maa12"
                    class="bg-secondary"
                  />
                </div>
                <div class="p-field">
                  <label for="12mas">12 Month Average Sale</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    disabled="true"
                    id="12mas"
                    v-model="associate.mas12"
                    class="bg-secondary"
                  />
                </div>
                <div class="p-field">
                  <label for="12maps">12 Month Average Product Sale</label>
                  <InputNumber
                    disabled="true"
                    id="12maps"
                    v-model="associate.maps12"
                    class="bg-secondary"
                  />
                </div>
                <div class="p-field">
                  <label for="12mimn">12 Month Individual Magic No</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    disabled="true"
                    id="12mimn"
                    v-model="associate.mimn12"
                    class="bg-secondary"
                  />
                </div>
                <div class="p-field">
                  <label for="12micmn"
                    >12 Month Individual Comparable Magic No</label
                  >
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    disabled="true"
                    id="12micmn"
                    v-model="associate.micmn12"
                    class="bg-secondary"
                  />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Address">
            <div class="p-grid">
              <div class="p-col-12">
                <div class="p-field">
                  <label for="address">Address</label>
                  <InputText id="address" v-model="associate.address" />
                </div>
              </div>
              <!--.p-col-12-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="city">City</label>
                  <InputText id="city" v-model="associate.city" />
                </div>
                <div class="p-field">
                  <label for="zipcode">Zip/Post Code</label>
                  <InputText id="zipcode" v-model="associate.zipCode" />
                </div>
                <div class="p-field">
                  <label for="companyext">Company Ext</label>
                  <InputText id="companyext" v-model="associate.companyExt" />
                </div>
                <div class="p-field">
                  <label for="jobtitle">Job Title</label>
                  <InputText id="jobtitle" v-model="associate.jobTitle" />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="region">Region</label>
                  <Dropdown
                    id="region"
                    v-model="associate.region"
                    :options="regionsList"
                      optionLabel="region_name"
                    :filter="true"
                    :class="{ 'p-invalid': submitted && associate.region  != null}"
                  >
                    <template #value="slotProps">
                      <span>
                        Province: {{ slotProps.value.province_name }} | Region:
                        {{ slotProps.value.region_name }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <span>
                        {{ slotProps.option.province_name }} |
                        {{ slotProps.option.region_name }}
                      </span>
                    </template>
                  </Dropdown>
                    <small class="p-invalid" v-if="submitted && associate.region  != null"
                    >Region is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="homePhone">Home Phone</label>
                  <InputText id="homePhone" v-model="associate.homePhone" />
                </div>
                <div class="p-field">
                  <label for="performanceNo">Performance No</label>
                  <InputNumber
                    :useGrouping="false"
                     mode="decimal"
                    :minFractionDigits="1"
                    id="performanceNo"
                    v-model="associate.performanceNo"
                  />
                </div>
                <div class="p-field">
                  <label for="hireDate">Hire Date</label>
                  <input
                    type="date"
                    v-model="associate.hireDate"
                    class="form-control"
                  />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Job Information">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="p-field">
                  <label for="jobClass">Job Class</label>
                  <Dropdown
                    id="jobClass"
                    v-model="associate.jobClass"
                    :options="jobClass"
                    optionLabel="value"
                  />
                </div>
                <div class="p-field">
                  <label for="homeStore">Home Store</label>
                  <Dropdown
                    id="homeStore"
                    v-model="associate.homeStore"
                    :options="storeList"
                    optionLabel="mall"
                    :filter="true"
                    :class="{ 'p-invalid': submitted && associate.homeStore   != null}"
                  />
                   <small class="p-invalid" v-if="submitted && associate.homeStore  != null"
                    >Home is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="store2">Store 2</label>
                  <Dropdown
                    id="store2"
                    v-model="associate.store2"
                    :options="storeList"
                    optionLabel="mall"
                    :class="{ 'p-invalid': submitted && associate.store2  != null }"
                    :filter="true"
                  />
                   <small class="p-invalid" v-if="submitted && associate.store2  != null"
                    >Store 2 is required.</small
                  >
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="store3">Store 3</label>
                  <Dropdown
                    id="store3"
                    v-model="associate.store3"
                    :options="storeList"
                    optionLabel="mall"
                    :class="{ 'p-invalid': submitted && associate.store3  != null }"
                    :filter="true"
                  />
                   <small class="p-invalid" v-if="submitted && associate.store3  != null"
                    >Store 3 is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="manager">Manager</label>
                  <Dropdown
                    id="manager"
                    v-model="associate.manager"
                    :options="managerList"
                    optionLabel="employee_id"
                    :filter="true"
                    :class="{ 'p-invalid': submitted && associate.manager  != null}"
                    >
                      <template #value="slotProps">
                        <span>
                          Manager Name : {{ slotProps.value.employee_firstname }} {{ slotProps.value.employee_name }} | {{ slotProps.value.employee_id }}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <span>
                          Manager Name : {{ slotProps.option.employee_firstname }} {{ slotProps.option.employee_name }} | {{ slotProps.option.employee_id }}
                        </span>
                      </template>
                  </Dropdown>
                   <small class="p-invalid" v-if="submitted && associate.manager  != null">Manager is required.</small>
                </div>
                <div class="p-field">
                  <label for="associateType">Associate Type</label>
                  <Dropdown
                    id="associateType"
                    v-model="associate.associateType"
                    :options="associateType"
                    optionLabel="key"
                  />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Identification">
            <div class="p-grid">
              <div class="p-col-6" v-if="hideDisableFields == false">
                <div class="p-field">
                  <label for="pin">Pin#</label>
                  <InputText
                    id="pin"
                    v-model="associate.pin"
                    @blur="verifyPin"
                    :class="{ 'p-invalid': submitted && !associate.pin }"
                  />
                  <small class="p-invalid" v-if="submitted && !associate.pin"
                    >Pin is required.</small
                  >
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="image">Upload User Picture (Optional)</label>
                  <FileUpload
                    mode="basic"
                    v-model="associate.picture"
                    accept="image/*"
                    :customUpload="true"
                    chooseLabel="Upload"
                  />
                </div>
                <div class="p-field">
                  <label for="description">Description</label>
                  <InputText id="description" v-model="associate.description" />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-12">
                <hr />
                <div class="p-d-flex p-jc-end">
                  <div class="p-mr-2">
                    <Button
                    v-if="permission == 'yes'"
                    label="Save"
                    icon="pi pi-check"
                    class="p-button-success"
                    @click="saveItem"
                  />
                  </div>
                  <div class="p-mr-2">
                    <Button
                      label="Close"
                      icon="pi pi-times"
                      class="p-button-danger"
                      @click="closeDialogBox"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Associate from "../service/Associate";

@Options({
  props: {
    associateDetail: Object,
  },
  watch: {
    associateDetail(obj) {
      if (obj.status == "openAssociate") {
        this.openDialog();
      } else if (obj.status == "updateAssociate") {
        this.editIemDialog(obj.employeeId);
      } else {
        this.dialogTitle = "";
      }
      this.permission = obj.permission;
      this.dialogTitle = obj.title;
    },
  },
  emits: ["updateAssociateStatus"],
})
export default class AssociateDialog extends Vue {
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle;
  private associates;
  private permission = "";
  private managerList;
  private storeList;
  private regionsList;
  private hideDisableFields = false;

  private jobClass = [
    { value: "District Manager" },
    { value: "Manager/Assist" },
    { value: "Production Only" },
    { value: "Customer Service" },
    { value: "HQ" },
    { value: "Universal" },
  ];

  private associateType = [
    { key: "Full Time", value: "0" },
    { key: "Part Time", value: "1" },
  ];

  private associate = {
    id: "",
    associateID: "",
    firstName: "",
    lastName: "",
    email: "",
    hourlyRate: 0,
    mimn: 0,
    micmn: 0,
    mas: 0,
    maa: 0,
    maa12: 0,
    mas12: 0,
    maps12: 0,
    mimn12: 0,
    micmn12: 0,
    address: "",
    city: "",
    zipCode: "",
    companyExt: "",
    jobTitle: "",
    region: {},
    homePhone: "",
    performanceNo: 0,
    hireDate: "",
    jobClass: { value: "District Manager" },
    homeStore: {},
    store2: {},
    store3: {},
    manager: {},
    associateType: { key: "Full Time", value: "0" },
    pin: "",
    description: "",
  };
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.associates = new Associate();
    this.toast = new Toaster();
  }

  mounted() {
    this.loadOpenDialogData();
  }

  verifyId() {
    if (this.associate.associateID.trim() != "") {
      this.associates
        .verifyAssociateId(this.associate.associateID)
        .then((res) => {
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
    }
  }

  verifyPin() {
    if (this.associate.pin.trim() != "") {
      this.associates.verifyAssociatePin(this.associate.pin).then((res) => {
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
      });
    }
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.productDialog = true;
    this.dialogTitle = "Add Associate";
    this.loadOpenDialogData();
  }

  loadOpenDialogData() {
    this.associates.openDialog().then((data) => {
      this.managerList = data.manager_list;
      this.storeList = data.store_list;
      this.regionsList = data.regions;
      this.associate.pin = data.employee_id;
    });
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.associate.firstName.trim() && this.associate.lastName.trim() && this.associate.email.trim() && this.associate.pin.trim() && Object.keys(this.associate.region).length > 0 && Object.keys(this.associate.homeStore).length > 0 && Object.keys(this.associate.store2).length > 0 && Object.keys(this.associate.store3).length > 0 && Object.keys(this.associate.manager).length > 0) {
      if (this.associate.id != "") {
        this.associates.updateItem(this.associate).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.associates.saveItem(this.associate).then((res) => {
          this.closeDialogBox();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;

      this.clearItem();
    }
    else
    {
      this.toast.showInfo('Please fill all the required fields');
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIemDialog(id) {
    this.submitted = false;
    this.dialogTitle = "Update Associate";
    this.hideDisableFields = true;

    if (id != "") {
      this.associates.getItem(id).then((res) => {
        if (res) {
         // this.managerList = res.manager_list;
         // this.storeList = res.store_list;
         // this.regionsList = res.regions;

          const region = this.regionFilter(res.single_emp.region_id);

          const manager = this.managerListFilter(res.single_emp.manager);

          const homeStore = this.storeFilter(res.single_emp.branch_id);
          const store2 = this.storeFilter(res.single_emp.branch_id_2);
          const store3 = this.storeFilter(res.single_emp.branch_id_3);

          const jobClass = this.jobClassFilter(res.single_emp.job_class);
          const associateType = this.associateTypeFilter(
            res.single_emp.employee_type
          );

          this.associate.id = res.single_emp.employee_id;
          this.associate.associateID = res.single_emp.employee_id;
          this.associate.firstName = res.single_emp.employee_firstname;
          this.associate.lastName = res.single_emp.employee_name;
          this.associate.email = res.single_emp.employee_email;
          this.associate.hourlyRate = res.single_emp.hourly_rate;
          this.associate.address = res.single_emp.employee_address;
          this.associate.city = res.single_emp.city;
          this.associate.zipCode = res.single_emp.zip;
          this.associate.companyExt = res.single_emp.companyext;
          this.associate.jobTitle = res.single_emp.job_title;

          this.associate.region = region;
          this.associate.homeStore = homeStore;
          this.associate.store2 = store2;
          this.associate.store3 = store3;
          this.associate.jobClass = jobClass;
          this.associate.manager = manager;
          this.associate.associateType = associateType;

          this.associate.hireDate = res.single_emp.hire_date;
          this.associate.homePhone = res.single_emp.user_contact_1;
          this.associate.performanceNo = res.single_emp.performance;
          this.associate.description = res.single_emp.employee_description;
          this.associate.maa = res.single_emp.average_alteration;
          this.associate.mimn = res.single_emp.magic;

          this.productDialog = true;
        }
      });
    } else {
      this.toast.showError("Could not be able to load the record invalid ID");
    }
  }

  // //USED TO FILTER REGIONS
  regionFilter(res) {
    let region = {};
    this.regionsList.filter((elem) => {
      if (elem.region_id == res) {
        region = elem;
      }
    });
    return region;
  }

  //USED TO FILTER MANAGERLIST
  managerListFilter(res) {
    let manager = {};
    this.managerList.filter((elem) => {
      if (elem.employee_id == res) {
        manager = elem;
      }
    });
    return manager;
  }

  //USED TO FILTER STORE
  storeFilter(res) {
    let selectedStore = {};
    this.storeList.filter((elem) => {
      if (elem.code == res) {
        selectedStore = elem;
      }
    });
    return selectedStore;
  }

  //USED TO FILTER JOB CLASS
  jobClassFilter(res) {
    let jobClass =  { value: "District Manager" };
    this.jobClass.filter((elem) => {
      if (elem.value == res) {
        jobClass = elem;
      }
    });
    return jobClass;
  }

  //USED TO FILTER ASSOCIATE TYPE
  associateTypeFilter(res) {
    let associateType = { key: "Full Time", value: "0" };
    this.associateType.filter((elem) => {
      if (elem.value == res) {
        associateType = elem;
      }
    });
    return associateType;
  }

  clearItem() {
    this.associate.id = "";
    this.associate.associateID = "";
    this.associate.firstName = "";
    this.associate.lastName = "";
    this.associate.email = "";
    this.associate.hourlyRate = 0;
    this.associate.address = "";
    this.associate.city = "";
    this.associate.zipCode = "";
    this.associate.companyExt = "";
    this.associate.jobTitle = "";

    this.associate.region = {};
    this.associate.homeStore = {};
    this.associate.store2 = {};
    this.associate.store3 = {};
    this.associate.jobClass = { value: "District Manager" };
    this.associate.manager = {};
    this.associate.associateType = { key: "Full Time", value: "0" };

    this.associate.hireDate = "";
    this.associate.homePhone = "";
    this.associate.performanceNo = 0;
    this.associate.description = "";
    this.associate.maa = 0;
    this.associate.mimn = 0;
  }

  closeDialogBox() {
    this.submitted = false;
    this.productDialog = false;
    this.clearItem();
    this.$emit("updateAssociateStatus");
  }
}
</script>
