
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";

interface ListTypes {
  employeeId: string;
  employeeFirstname: string;
  employeeName: string;
  employeePassword: string;
  userContact1: string;
}

@Options({
   components: {},
  props: {
    associateBarcodeDetail: Object,
  },
  watch: {
    associateBarcodeDetail(obj) {
      this.productDialog = obj.status;
      this.lists = this.camelizeKeys(obj.associateList);
    },
  },
  emits: ["updateAssociateBarcodeStatus"],
})
export default class AssociateBarcodeDialog extends Vue {
  private toast;
  private productDialog = false;
  private productDialogBarcode = false;
  private lists: ListTypes [] = [];
  private modifiedLists: ListTypes [] = [];

  private barcodeOptions = { 
    displayValue: false,
    lineColor: '#000',
    format: 'CODE39',
    width: 2,
    height: 50,
    font: 'fantasy',
    textAlign: 'left',
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.toast = new Toaster();
  }

  mounted() {
   //
  }

  closeDialogBox() {
    this.productDialog = false;
    this.$emit("updateAssociateBarcodeStatus");
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  get generateBarcodes ()
  {
    this.modifiedLists = [];
    this.productDialogBarcode = false;

    this.lists.forEach(e => {
        this.modifiedLists.push({
          employeeId: e.employeeId,
          employeeFirstname: e.employeeFirstname,
          employeeName: e.employeeName,
          employeePassword: '',
          userContact1: e.userContact1
        });
    });

    return  this.modifiedLists;
  }

  showBarcodes()
  {
    this.productDialogBarcode = true;
  }

  printDoc()
  {
    window.print();
  }

  convertString(pin)
  {
    return String(pin);
  }

}
