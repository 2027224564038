<template>
  <Dialog
    v-model:visible="productDialog"
    :maximizable="false"
    position="top"
    class="p-fluid p-m-0 p-dialog-maximized"
    :modal="true"
    :closable="true"
    @hide="closeDialogBox"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon noprint">
        <i class="pi pi-user"></i> Associate Details
      </h5>
    </template>
    <div class="p-grid noprint">
      <div class="p-col-12">
        <DataTable
          :value="generateBarcodes"
          :lazy="false"
          :paginator="false"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column
            headerStyle="width: 200px"
            header="Associate Pin"
          >
           <template #body="slotProps" >
            <InputText v-model="slotProps.data.employeePassword" style="width:250px" />
            </template>
          </Column>
          <Column
            field="employeeId"
            headerStyle="width: 150px"
            header="Associate ID"
          ></Column>
          <Column
            headerStyle="width: 300px"
            header="Name"
          >
           <template #body="slotProps">
              {{  slotProps.data.employeeFirstname }}
              {{  slotProps.data.employeeName }}
            </template>
          </Column>
          <Column
            field="userContact1"
            headerStyle="width: 200px"
            header="Contact No"
          >
          </Column>
        </DataTable>  
        <Button @click="showBarcodes" class="p-button-success" label="Generate Barcode" icon="fa fa-bar-chart" />
      </div>
    </div>
    <div class="p-grid p-mt-4 printing-section" v-if="productDialogBarcode">
      <div class="p-col-11">
         <h4> <i class="pi pi-user"></i> Store Associates List :</h4>
      </div>
      <div class="p-col-1 p-text-right">
        <Button class="p-button-primary noprint" label="Print" @click="printDoc" icon="pi pi-print" />
      </div>
      <div class="p-col-12">
        <DataTable
          :value="modifiedLists"
          :lazy="false"
          :paginator="false"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
        <Column
            headerStyle="width: 300px"
            header="Barcode"
          >
           <template #body="slotProps" >
             <span  v-if="slotProps.data.employeePassword != ''">
                <vue-barcode  :value="convertString(slotProps.data.employeePassword)" :options="barcodeOptions" tag="svg" ></vue-barcode>
              </span>
            </template>
          </Column>
          <Column
            field="employeeId"
            headerStyle="width: 150px"
            header="Associate ID"
          ></Column>
          <Column
            headerStyle="width: 300px"
            header="Name"
          >
           <template #body="slotProps">
              {{  slotProps.data.employeeFirstname }}
              {{  slotProps.data.employeeName }}
            </template>
          </Column>
          <Column
            field="userContact1"
            headerStyle="width: 200px"
            header="Contact No"
          >
          </Column>
        </DataTable>  
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";

interface ListTypes {
  employeeId: string;
  employeeFirstname: string;
  employeeName: string;
  employeePassword: string;
  userContact1: string;
}

@Options({
   components: {},
  props: {
    associateBarcodeDetail: Object,
  },
  watch: {
    associateBarcodeDetail(obj) {
      this.productDialog = obj.status;
      this.lists = this.camelizeKeys(obj.associateList);
    },
  },
  emits: ["updateAssociateBarcodeStatus"],
})
export default class AssociateBarcodeDialog extends Vue {
  private toast;
  private productDialog = false;
  private productDialogBarcode = false;
  private lists: ListTypes [] = [];
  private modifiedLists: ListTypes [] = [];

  private barcodeOptions = { 
    displayValue: false,
    lineColor: '#000',
    format: 'CODE39',
    width: 2,
    height: 50,
    font: 'fantasy',
    textAlign: 'left',
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.toast = new Toaster();
  }

  mounted() {
   //
  }

  closeDialogBox() {
    this.productDialog = false;
    this.$emit("updateAssociateBarcodeStatus");
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  get generateBarcodes ()
  {
    this.modifiedLists = [];
    this.productDialogBarcode = false;

    this.lists.forEach(e => {
        this.modifiedLists.push({
          employeeId: e.employeeId,
          employeeFirstname: e.employeeFirstname,
          employeeName: e.employeeName,
          employeePassword: '',
          userContact1: e.userContact1
        });
    });

    return  this.modifiedLists;
  }

  showBarcodes()
  {
    this.productDialogBarcode = true;
  }

  printDoc()
  {
    window.print();
  }

  convertString(pin)
  {
    return String(pin);
  }

}
</script>

<style scoped>

@media print {
   .noprint {
      visibility: hidden;
   }

   .printing-section
   {
     position: absolute;
     top: 0;
   }
}

</style>
