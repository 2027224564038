
import { Options, Vue,mixins } from "vue-class-component";
import Backroom from "../service/Backroom";
import { camelCase } from "lodash";
import Toaster from "../helpers/Toaster";
import UtilityOptions from "../mixins/UtilityOptions";
import { useStore } from "../store";

interface OrderDetails {
  dueDate: string;
  dueTime: string;
  description: string;
  id: string;
}

interface OrderItemList {
  itemName: string;
  itemQty: number;
  tag3: string;
  salesItemId: number;
  slectedItem: string;
}

interface OrderService {
  serviceName: string;
  express: string;
  workingStatus: string;
  checkStatus: string;
  image: string;
  id: string;
  dueDate: string;
  description: string;
  dueTime: string;
  itemList: OrderItemList[];
}

@Options({
  components: {},
  props: {
    associateDetail: Object,
  },
  watch: {
    associateDetail(obj) {
      if (obj.status) {
        this.product.itemID = obj.receiptID;
        this.orderType = obj.orderType;
        this.orderStatus = obj.orderStatus;
        this.orderAction = obj.orderAction;
        this.serviceName = obj.serviceName;
        this.orderTotalBill = obj.orderTotalBill;
        this.openDialog();
      }
    },
  },
  emits: ["updateOrderStatus"],
})
export default class AssocaiteBackroomPickup extends mixins(UtilityOptions) {
  private productDialog = false;
  private submitted = false;
  private toast;
  private receiptID = "";
  private associateList = [];
  private backroomService;
  private imagePath = "";
  private orderType = "";
  private orderStatus = "";
  private orderAction = "";
  private dailogTitle = "";
   private store = useStore();

  private product = {
    itemID: "",
    pin: "",
  };

  private OrderDetail: OrderDetails = {
    dueDate: "",
    dueTime: "",
    description: "",
    id: "",
  };

  private OrderServices: OrderService[] = [];

  private selectedItems: string[] = [];
  private selectedOrderBills: number[] = [];
  private selectedServiceName: string[] = [];

  created() {
    this.backroomService = new Backroom();
    this.toast = new Toaster();
    this.imagePath = this.backroomService.getBaseURL() + "uploads/services/";
  }

  numPad(key) {
    if (key == "clear") {
      this.product.pin = "";
    } else if (key == "ok") {
      this.performAction();
    } else if (key == "none") {
      //DO NOTHING
    } else {
      this.product.pin = this.product.pin + key;
    }
  }

  openDialog() {
    if (this.orderAction == "ready") {
      this.dailogTitle = "Mark Order as Ready";
    } else if (this.orderAction == "cancel") {
      this.dailogTitle = "Revert the order";
    } else if (this.orderAction == "start") {
      this.dailogTitle = "Pick Order";
    }

    this.productDialog = true;
    this.backroomService
      .getOrderStatus(this.product, this.orderType, this.orderStatus)
      .then((res) => {
        const receiptInfo = this.camelizeKeys(res.order_details);
        const receiptItems = this.camelizeKeys(res.order_items);

        this.OrderDetail.id = receiptInfo.id;
        this.OrderDetail.dueDate = receiptInfo.dueDate;
        this.OrderDetail.dueTime = receiptInfo.dueTime;
        this.OrderDetail.description = receiptInfo.description;
        this.OrderServices = receiptItems;
      });
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  closeDialogBox() {
    this.productDialog = false;
    this.$emit("updateOrderStatus");
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };


  addItem(id, totalBill, serviceName) {
    if (this.selectedItems.includes(id)) {
      const index = this.selectedItems.indexOf(id);

      this.selectedItems.splice(index, 1);
      this.selectedOrderBills.splice(index, 1);
      this.selectedServiceName.splice(index, 1);
    } else {
      this.selectedItems.push(id);
      this.selectedOrderBills.push(totalBill);
      this.selectedServiceName.push(serviceName);
    }
  }

  clearData() {
    this.product = {
      itemID: "",
      pin: "",
    };

    this.selectedItems = [];
    this.selectedOrderBills = [];
    this.selectedServiceName = [];
  }

  performAction() {
    this.submitted = true;

    if (this.selectedItems.length > 0) {
      if (this.product.itemID == "" || this.product.pin == "") {
        this.toast.showWarning("Please scan receipt and enter associate PIN");
      } else {
        if (this.product.itemID.trim()) {
          this.backroomService
            .saveOrderStatus(
              this.product,
              this.selectedItems,
              this.selectedOrderBills,
              this.selectedServiceName,
              this.orderAction
            )
            .then((res) => {
              this.closeDialogBox();
              this.submitted = false;
              this.toast.handleResponse(res);
              this.clearData();
            });
        }
      }
    } else {
      this.toast.showWarning("Please choose any item to perform any action");
    }
  }
}
