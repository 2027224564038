
import { Options, Vue } from "vue-class-component";
import AssociatePIN from "../service/AssociatePIN";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import router from "../router";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj)
    {
      if(obj.actionType == 'repon')
      {
        this.PIN = '';
        this.statusDialog = true;
        this.associateType = obj.associateType;
      }
      else
      {
        this.associateType = obj.associateType;
      }
    },
  },
  components: {},
  emits: ["emitAssociatePinEvent"],
})
export default class AssociatePinVerification extends Vue {
  private statusDialog = true;
  private toast;
  private assoTxn;
  private PIN = "";
  private associateType = "";
  private empId = "";
  private empFirstName = "";
  private empLastName = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.assoTxn = new AssociatePIN();
    this.toast = new Toaster();
  }

  associateKey(key) {
    if (key == "clear") {
      this.PIN = "";
    } else if (key == "done") {
        this.verifyAssociate();
    }
    else
    {
      this.PIN = this.PIN + key.trim();
    }
  }

  verifyAssociate() {
    this.assoTxn.verifyAssociate(this.PIN,this.associateType).then((data) => {
      const d = this.camelizeKeys(data);
      if (d[0] == "not_found") {
        this.toast.showError("Invalid associate pin");
        this.redirectHome();
      } else if (d[0] == "Notcheckout") {
        this.empId = d[1].employeeId;
        this.empFirstName = d[1].employeeFirstname;
        this.empLastName = d[1].employeeName;
        if (this.empId != "") {
          this.$emit("emitAssociatePinEvent", [
            this.empId,
            this.empFirstName,
            this.empLastName,
          ]);

          this.statusDialog = false;
        } else {
          this.toast.showError("Sorry something went wrong please try again");
          this.redirectHome();
        }
      } else if (d[0] == "Notcheckin") {
        this.toast.showError("Please log in your self");
        this.redirectHome();
      } else {
        this.toast.showError("Sorry cannot process this request");
        this.redirectHome();
      }
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  redirectHome() {
    router.push({ path: "/store/dashboard", params: {} });
  }
}
