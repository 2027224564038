<template>
  <Dialog
    v-model:visible="productDialog"
    :style="{ width: '70vw' }"
    :closable="true"
    @hide="closeDialogBox"
    position="top"
    class="p-fluid  p-m-0"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-check-circle"></i> {{ dailogTitle }}
      </h5>
    </template>
    <div class="p-grid p-ai-start vertical-container">
       <div style="height: 0.3em; background-color: #eee; margin-top: 9.2vh">
        <ProgressBar
          v-if="progressBar"
          mode="indeterminate"
          style="height: 0.2em"
        />
      </div>
      <div class="p-col-9 p-md-9 p-lg-9 cover-receipt">
        <ul
          class="visitor list-unstyled list-unstyled-border inv_employee_pickup"
          v-if="OrderServices.length"
        >
          <li class="p-p-0 p-m-0">
            <div class="p-0 media-body">
              <p class="p-m-0">Order ID : {{ OrderDetail.id }}</p>
              <p class="p-m-0">
                <span class="pull-right">
                  Due : {{ formatDate(OrderDetail.dueDate) }} |
                  {{ formatTime(OrderDetail.dueTime) }}</span
                >
              </p>
              <p class="p-m-0">
                <span>Memo : {{ OrderDetail.description }}</span>
              </p>
            </div>
          </li>
          <template v-for="services in OrderServices" :key="services">
            <li class="p-p-0 p-m-0">
              <div class="media-body">
                <div
                  style="background-color: #c00; color: #fff"
                  class="p-1 pt-2"
                >
                  Status :
                  <strong class="pull-right"
                    >{{ services.workingStatus.toUpperCase() }}/{{
                      services.checkStatus.toUpperCase()
                    }}</strong
                  >
                </div>
              </div>
            </li>
            <li class="p-p-1 p-m-0 border-bottom">
              <div class="service_row_cls">
                <p class="p-m-0 p-p-0">
                  <img
                    class="mr-2 mt-2 mb-2 rounded-circle"
                    width="25"
                    :src="imagePath + services.image"
                    alt="avatar"
                  />
                  {{ services.serviceName }}
                  <i
                    v-if="services.express != 'no'"
                    class="pi pi-circle-on order-express-tag p-mt-2 p-mr-1"
                  ></i>
                </p>
                <p class="p-m-0 p-p-1 text-right">
                  Item ID : {{ services.id }}
                </p>
                <p class="p-m-0 p-p-1" v-if="services.express != 'no'">
                  Express Memo: {{ formatDate(services.description) }}
                </p>
                <p class="p-m-0 p-p-1" v-if="services.express != 'no'">
                  Due Date {{ formatDate(services.dueDate) }} |
                  {{ formatTime(services.dueTime) }}
                </p>
                <p class="p-mt-2 p-p-1">
                  Choose Item:
                  <span class="pull-right">
                    <input
                      type="checkbox"
                      v-model="selectedItems"
                      :value="services.id"
                      style="width: 30px; height: 30px; margin-top: -10px"
                      @click="
                        addItem(
                          services.id,
                          services.totalBill,
                          services.serviceName
                        )
                      "
                    />
                  </span>
                </p>
              </div>
            </li>
            <li
              class="p-p-0 p-m-0 border-bottom"
              v-for="itemList in services.itemList"
              :key="itemList"
            >
              <p class="pt-2">
                <i class="pi pi-arrow-circle-right"></i>
                {{ itemList.itemName }}
              </p>
              <p class="p-m-0 p-p-0" v-if="itemList.tag3 != ''">
                <i class="pi pi-tags"></i> {{ itemList.tag3 }}
              </p>
            </li>
          </template>
        </ul>
        <div class="load-prep_service-details" v-if="!OrderServices.length">
          <h4 class="p-mt-4">
            No Invoice details
            <i class="pi pi-question" aria-hidden="true"></i>
          </h4>
          <p>Please scan Invoice ID to load invoice services & items.</p>
        </div>
      </div>
      <div class="p-col-3 p-md-3 p-lg-3">
        <div class="p-field">
          <input
            v-model.trim="product.pin"
            type="password"
            autofocus
            @keyup.enter="numPad('ok')"
            placeholder="Associate PIN"
            class="form-control"
            :class="{ 'p-invalid': submitted && !product.pin }"
          />
          <small class="p-invalid" v-if="submitted && !product.pin"
            >PIN is required.</small
          >
        </div>
        <span class="p-buttonset">
          <Button
            label="1"
            @click="numPad(1)"
            class="p-button-primary key-pad-button"
          />
          <Button
            label="2"
            @click="numPad(2)"
            class="p-button-primary key-pad-button"
          />
          <Button
            label="3"
            @click="numPad(3)"
            class="p-button-primary key-pad-button"
          />
        </span>
        <span class="p-buttonset">
          <Button
            label="4"
            @click="numPad(4)"
            class="p-button-primary key-pad-button"
          />
          <Button
            label="5"
            @click="numPad(5)"
            class="p-button-primary key-pad-button"
          />
          <Button
            label="6"
            @click="numPad(6)"
            class="p-button-primary key-pad-button"
          />
        </span>
        <span class="p-buttonset">
          <Button
            label="7"
            @click="numPad(7)"
            class="p-button-primary key-pad-button"
          />
          <Button
            label="8"
            @click="numPad(8)"
            class="p-button-primary key-pad-button"
          />
          <Button
            label="9"
            @click="numPad(9)"
            class="p-button-primary key-pad-button"
          />
        </span>
        <span class="p-buttonset">
          <Button
            label="0"
            @click="numPad(0)"
            class="p-button-primary key-pad-button"
          />
          <Button
            label="x"
            @click="numPad('clear')"
            class="p-button-primary key-pad-button"
          />
          <Button
            label="OK"
            @click="numPad('ok')"
            class="p-button-warning key-pad-button"
          />
        </span>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue,mixins } from "vue-class-component";
import Backroom from "../service/Backroom";
import { camelCase } from "lodash";
import Toaster from "../helpers/Toaster";
import UtilityOptions from "../mixins/UtilityOptions";
import { useStore } from "../store";

interface OrderDetails {
  dueDate: string;
  dueTime: string;
  description: string;
  id: string;
}

interface OrderItemList {
  itemName: string;
  itemQty: number;
  tag3: string;
  salesItemId: number;
  slectedItem: string;
}

interface OrderService {
  serviceName: string;
  express: string;
  workingStatus: string;
  checkStatus: string;
  image: string;
  id: string;
  dueDate: string;
  description: string;
  dueTime: string;
  itemList: OrderItemList[];
}

@Options({
  components: {},
  props: {
    associateDetail: Object,
  },
  watch: {
    associateDetail(obj) {
      if (obj.status) {
        this.product.itemID = obj.receiptID;
        this.orderType = obj.orderType;
        this.orderStatus = obj.orderStatus;
        this.orderAction = obj.orderAction;
        this.serviceName = obj.serviceName;
        this.orderTotalBill = obj.orderTotalBill;
        this.openDialog();
      }
    },
  },
  emits: ["updateOrderStatus"],
})
export default class AssocaiteBackroomPickup extends mixins(UtilityOptions) {
  private productDialog = false;
  private submitted = false;
  private toast;
  private receiptID = "";
  private associateList = [];
  private backroomService;
  private imagePath = "";
  private orderType = "";
  private orderStatus = "";
  private orderAction = "";
  private dailogTitle = "";
   private store = useStore();

  private product = {
    itemID: "",
    pin: "",
  };

  private OrderDetail: OrderDetails = {
    dueDate: "",
    dueTime: "",
    description: "",
    id: "",
  };

  private OrderServices: OrderService[] = [];

  private selectedItems: string[] = [];
  private selectedOrderBills: number[] = [];
  private selectedServiceName: string[] = [];

  created() {
    this.backroomService = new Backroom();
    this.toast = new Toaster();
    this.imagePath = this.backroomService.getBaseURL() + "uploads/services/";
  }

  numPad(key) {
    if (key == "clear") {
      this.product.pin = "";
    } else if (key == "ok") {
      this.performAction();
    } else if (key == "none") {
      //DO NOTHING
    } else {
      this.product.pin = this.product.pin + key;
    }
  }

  openDialog() {
    if (this.orderAction == "ready") {
      this.dailogTitle = "Mark Order as Ready";
    } else if (this.orderAction == "cancel") {
      this.dailogTitle = "Revert the order";
    } else if (this.orderAction == "start") {
      this.dailogTitle = "Pick Order";
    }

    this.productDialog = true;
    this.backroomService
      .getOrderStatus(this.product, this.orderType, this.orderStatus)
      .then((res) => {
        const receiptInfo = this.camelizeKeys(res.order_details);
        const receiptItems = this.camelizeKeys(res.order_items);

        this.OrderDetail.id = receiptInfo.id;
        this.OrderDetail.dueDate = receiptInfo.dueDate;
        this.OrderDetail.dueTime = receiptInfo.dueTime;
        this.OrderDetail.description = receiptInfo.description;
        this.OrderServices = receiptItems;
      });
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  closeDialogBox() {
    this.productDialog = false;
    this.$emit("updateOrderStatus");
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };


  addItem(id, totalBill, serviceName) {
    if (this.selectedItems.includes(id)) {
      const index = this.selectedItems.indexOf(id);

      this.selectedItems.splice(index, 1);
      this.selectedOrderBills.splice(index, 1);
      this.selectedServiceName.splice(index, 1);
    } else {
      this.selectedItems.push(id);
      this.selectedOrderBills.push(totalBill);
      this.selectedServiceName.push(serviceName);
    }
  }

  clearData() {
    this.product = {
      itemID: "",
      pin: "",
    };

    this.selectedItems = [];
    this.selectedOrderBills = [];
    this.selectedServiceName = [];
  }

  performAction() {
    this.submitted = true;

    if (this.selectedItems.length > 0) {
      if (this.product.itemID == "" || this.product.pin == "") {
        this.toast.showWarning("Please scan receipt and enter associate PIN");
      } else {
        if (this.product.itemID.trim()) {
          this.backroomService
            .saveOrderStatus(
              this.product,
              this.selectedItems,
              this.selectedOrderBills,
              this.selectedServiceName,
              this.orderAction
            )
            .then((res) => {
              this.closeDialogBox();
              this.submitted = false;
              this.toast.handleResponse(res);
              this.clearData();
            });
        }
      }
    } else {
      this.toast.showWarning("Please choose any item to perform any action");
    }
  }
}
</script>

<style scoped>
.custom-button {
  padding: 11px;
  margin-top: 2px;
  border: none;
  width: 98%;
}

.load-prep_service-details {
  background-color: #eee;
  height: 61.5vh;
  border: 1px solid #ccc;
  text-align: center;
  color: #333;
  width: 100%;
}

.key-pad-button {
  width: 6vw;
  height: 10vh;
  margin: 1px;
}

.service_row_cls {
  background-color: #74e2e2;
}

.order-express-tag {
  float: right;
  color: red;
}

.border-bottom {
  border-bottom: 1px solid #333;
}

.cover-receipt {
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 3px;
}
</style>
