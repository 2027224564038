<template>
  <Dialog
    v-model:visible="statusDialog"
    :style="{ width: '30vw' }"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid"
    :showHeader="false"
  >
    <div class="p-d-flex p-jc-between p-mb-3">
      <div class="p-mr-2">
        <h5><i class="pi pi-user"></i> Associate PIN</h5>
      </div>
      <div class="p-mr-2">
        <Button
          class="p-button-primary dashboard-link"
          icon="pi pi-home"
          label="Dashboard"
          @click="redirectHome()"
        />
      </div>
    </div>
    <hr />
    <div class="p-field">
      <Password
        autoFocus
        v-model="PIN"
        @keyup.enter="associateKey('done')"
        :feedback="false"
      />
    </div>
    <span class="p-buttonset">
      <Button
        label="1"
        @click="associateKey('1')"
        class="p-button-primary key-pad-btn"
      />
      <Button
        label="2"
        @click="associateKey('2')"
        class="p-button-primary key-pad-btn"
      />
      <Button
        label="3"
        @click="associateKey('3')"
        class="p-button-primary key-pad-btn"
      />
    </span>
    <span class="p-buttonset">
      <Button
        label="4"
        @click="associateKey('4')"
        class="p-button-primary key-pad-btn"
      />
      <Button
        label="5"
        @click="associateKey('5')"
        class="p-button-primary key-pad-btn"
      />
      <Button
        label="6"
        @click="associateKey('6')"
        class="p-button-primary key-pad-btn"
      />
    </span>
    <span class="p-buttonset">
      <Button
        label="7"
        @click="associateKey('7')"
        class="p-button-primary key-pad-btn"
      />
      <Button
        label="8"
        @click="associateKey('8')"
        class="p-button-primary key-pad-btn"
      />
      <Button
        label="9"
        @click="associateKey('9')"
        class="p-button-primary key-pad-btn"
      />
    </span>
    <span class="p-buttonset">
      <Button
        label="0"
        @click="associateKey('0')"
        class="p-button-primary key-pad-btn"
      />
      <Button
        icon="pi pi-times"
        @click="associateKey('clear')"
        class="p-button-primary key-pad-btn"
      />
      <Button
        icon="pi pi-key"
        @click="associateKey('done')"
        class="p-button-warning key-pad-btn"
      />
    </span>
  </Dialog>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AssociatePIN from "../service/AssociatePIN";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import router from "../router";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj)
    {
      if(obj.actionType == 'repon')
      {
        this.PIN = '';
        this.statusDialog = true;
        this.associateType = obj.associateType;
      }
      else
      {
        this.associateType = obj.associateType;
      }
    },
  },
  components: {},
  emits: ["emitAssociatePinEvent"],
})
export default class AssociatePinVerification extends Vue {
  private statusDialog = true;
  private toast;
  private assoTxn;
  private PIN = "";
  private associateType = "";
  private empId = "";
  private empFirstName = "";
  private empLastName = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.assoTxn = new AssociatePIN();
    this.toast = new Toaster();
  }

  associateKey(key) {
    if (key == "clear") {
      this.PIN = "";
    } else if (key == "done") {
        this.verifyAssociate();
    }
    else
    {
      this.PIN = this.PIN + key.trim();
    }
  }

  verifyAssociate() {
    this.assoTxn.verifyAssociate(this.PIN,this.associateType).then((data) => {
      const d = this.camelizeKeys(data);
      if (d[0] == "not_found") {
        this.toast.showError("Invalid associate pin");
        this.redirectHome();
      } else if (d[0] == "Notcheckout") {
        this.empId = d[1].employeeId;
        this.empFirstName = d[1].employeeFirstname;
        this.empLastName = d[1].employeeName;
        if (this.empId != "") {
          this.$emit("emitAssociatePinEvent", [
            this.empId,
            this.empFirstName,
            this.empLastName,
          ]);

          this.statusDialog = false;
        } else {
          this.toast.showError("Sorry something went wrong please try again");
          this.redirectHome();
        }
      } else if (d[0] == "Notcheckin") {
        this.toast.showError("Please log in your self");
        this.redirectHome();
      } else {
        this.toast.showError("Sorry cannot process this request");
        this.redirectHome();
      }
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  redirectHome() {
    router.push({ path: "/store/dashboard", params: {} });
  }
}
</script>

<style scoped>

.dashboard-link {
  background-color: #000;
  color: #fff;
}

.dashboard-link:hover {
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

.key-pad-btn {
  padding: 20px;
  border: 1px solid #5086bb;
  font-size: 22px;
}
</style>
