
import { mixins, Options } from "vue-class-component";
import moment from "moment";
import { useStore, ActionTypes } from "../store";
import BuyBackService from "../service/BuyBackService";
import Toaster from "../helpers/Toaster";
import { camelCase } from "lodash";
import PaymentScreen from "../components/PaymentScreen.vue";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  inheritAttrs: false,
  components: {
    PaymentScreen,
  },
  emits: ["searchReceiptEvent", "updateRedoReceipt", "clearCheckOutEvent"],
})
export default class BuyBack extends mixins(UtilityOptions){
  private dialogTilte = " Buyback Purchase Payments";
  private txnService;
  private customerID = "";
  private referralOrCorp = "";
  private customerName = "";
  private CustomerNeedles = 0;
  private paymentDialog = false;
  private checkedItems: string[] = [];
  private fitInNames = [
    {
      id: "",
      employeeFirstname: "",
      employeeName: "",
    },
  ];

  private submitted = false;
  private imagePath = "";
  private toast;
  private vuexStore = useStore();
  private orderDetail = {
    dueDate: "",
    dueTime: "",
    description: "",
    cusId: "",
  };
  private orderItems = [
    {
      express: "",
      image: "",
      serviceName: "",
      totalBill: "",
      saleItemId: "",
      adminApprove: "",
      checkStatus: "",
      workingStatus: "",
      description: "",
      dueDate: "",
      dueTime: "",
    },
  ];
  private buybackItems = {
    id: "",
    customerName: "",
    address: "",
    chequeNeedCustomer: "Customer",
    payoutInStore: "Yes",
    totalOfGarments: 0,
    refundAlterationCus: "Yes",
    fillInPrice: 0,
    totalToPaid: 0,
    reason: "Quality",
    garmentDescription: "",
    receiptInMail: "Yes",
    garmentInMail: "Yes",
    employeeId: "",
    managerWhoApproved: "",
    fitExpertName: "",
    sewExpertName: "",
    associateResponsible: "",
  };

  private receiptPayments = [
    {
      paymentType: "",
      transTotalAmount: 0,
    },
  ];

  get receiptID() {
    return this.vuexStore.getters.getReceiptID;
  }
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.txnService = new BuyBackService();
    this.toast = new Toaster();
    this.imagePath = this.txnService.getBaseURL() + "uploads/services/";
  }
  mounted() {
    this.getReceiptBB();
  }

  getReceiptBB() {
    this.txnService.getBBDetails(this.receiptID).then((data) => {
      const itemsDetail = this.camelizeKeys(data);
      this.receiptPayments = itemsDetail.orderDetails.invoicePayment;
      this.orderDetail = itemsDetail.orderDetails;
      this.orderItems = itemsDetail.orderItems;
      this.customerID = itemsDetail.orderDetails.cusId;
      this.customerName = itemsDetail.orderDetails.customer;
      this.buybackItems.customerName = itemsDetail.orderDetails.customer;
      this.referralOrCorp = itemsDetail.orderDetails.referalOrCorp;
      this.buybackItems.address = itemsDetail.customerDetails.address;
      this.fitInNames = itemsDetail.fitName;
      this.buybackItems.sewExpertName = itemsDetail.orderDetails.employeeName;
      this.CustomerNeedles = itemsDetail.customerDetails.custStoreCredit;
      this.buybackItems.customerName = itemsDetail.orderDetails.customer;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  get calculateTotalBill() {
    let alterationPrice = 0;

    this.buybackItems.fillInPrice = this.getBuyBackItem;
    this.buybackItems.fitExpertName = this.getFitInNames;

    if (this.buybackItems.refundAlterationCus == "Yes") {
      alterationPrice = Number(this.getBuyBackItem);
    } else {
      alterationPrice = 0;
    }

    const totalGarment = Number(this.buybackItems.totalOfGarments);
    let totalBB = alterationPrice + totalGarment - this.reduceNeedleAmt;
    totalBB     = (totalBB < 0 ? 0 : totalBB);
    this.buybackItems.totalToPaid = Number(totalBB);
    return totalBB;
  }

  get getBuyBackItem() {
    let itemTotal = 0;

    this.orderItems.forEach((e) => {
      this.checkedItems.forEach((f) => {
        if (e.saleItemId == f) {
          itemTotal = itemTotal + Number(e.totalBill);
        }
      });
    });

    return itemTotal;
  }

  get getFitInNames() {
    let names = "";

    this.fitInNames.forEach((e) => {
      this.checkedItems.forEach((f) => {
        if (e.id == f) {
          names = names + e.employeeFirstname + " " + e.employeeName + " , ";
        }
      });
    });

    return names;
  }

  proceedNext() {
    this.submitted = true;
    const validate = this.validateFields();
    if (validate) {
      if (
        this.buybackItems.payoutInStore == "Yes" &&
        this.buybackItems.totalToPaid > 50
      ) {
        this.toast.showWarning(
          "Payout in store is exceeding",
          "You are not allowed to pay in store if buyback amount is greater then $50",
          "warning"
        );
      }
      if (this.buybackItems.totalToPaid <= 0) {
        this.toast.showError(
          "Invalid buyback amount. Amount must be greater then 0"
        );
      } else if (this.buybackItems.employeeId == "") {
        this.toast.showError("Please enter pin of associate of store");
      } else {
        this.verfiyManagerAndAssociatePin();
      }
    } else {
      this.toast.showError("Please fill all the required fields");
    }
  }

  verfiyManagerAndAssociatePin() {
    this.txnService
      .verifyPINS(
        this.buybackItems.managerWhoApproved,
        this.buybackItems.employeeId
      )
      .then((res) => {
        this.toast.handleResponse(res);
        if (res.alert == "info") {
          if (this.buybackItems.chequeNeedCustomer == "N/A") {
            this.vuexStore.dispatch(
              ActionTypes.TOTAL_BILL,
              this.buybackItems.totalToPaid
            );
            this.paymentDialog = true;
          } else if (this.buybackItems.chequeNeedCustomer == "Customer"  || this.buybackItems.chequeNeedCustomer == 'Store') {
            this.getProceededPayments([]);
          } else {
            //
          }
        }
      });
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList) {
    const drawerStatus = this.shouldOpenDrawer(paymentList);

    this.txnService
      .sendBBReq(
        this.checkedItems,
        this.buybackItems,
        paymentList,
        this.customerID,
        this.receiptID,
        drawerStatus
      )
      .then((res) => {
        if (res.alert == "info") {
          this.$emit("clearCheckOutEvent");
        }
        this.toast.handleResponse(res);
      });
  }
  validateFields() {
    if (
      this.buybackItems.fitExpertName.trim() == "" ||
      this.buybackItems.sewExpertName.trim() == "" ||
      this.buybackItems.associateResponsible.trim() == "" ||
      this.buybackItems.customerName.trim() == "" ||
      this.buybackItems.address.trim() == ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  get needleAmount() {
    let amount = 0;

    this.receiptPayments.forEach((e) => {
      if (e.paymentType == "Needle") {
        amount = amount + e.transTotalAmount;
      }
    });

    return Number(amount);
  }

  get reduceNeedleAmt()
  {
    const checkedItems = this.checkedItems.length;
    const itemsQty = this.orderItems.length;
    const needle = this.needleAmount;
    let  avgNeedles = 0;

    if(itemsQty != 0)
    {
      avgNeedles = needle / itemsQty;
    }
    return avgNeedles * checkedItems;
  }
}
